<template>
  <form>
    <div>
      <v-toolbar 
        flat 
        color="#002166" 
        dark>
        <v-toolbar-title>Meu Perfil</v-toolbar-title>
      </v-toolbar>
      <v-expansion-panels 
        v-model="panel" 
        :disabled="disabled" 
        multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header>Dados Pessoais</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-col>
              <p>Nome: {{ personalData.nomFun }}</p>
              <p>Data de Nascimento: {{ personalData.datNas }}</p>
              <p>CPF: {{ personalData.numCpf }}</p>
              <p>RG: {{ personalData.numCid }}</p>
              <p>Orgão Expedidor: {{ personalData.emiCid }}</p>
              <p>Registro Funcional: {{ personalData.numCad }}</p>
              <p>Admissão: {{ personalData.datAdm }}</p>
            </v-col>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Empresa</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>Empresa: {{ personalData.nomEmp }}</p>
            <p>CNPJ: {{ personalData.numCgc }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Cargo</v-expansion-panel-header>
          <v-expansion-panel-content>
            Cargo: {{ personalData.titCar }}
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Centros de Custo</v-expansion-panel-header>
          <v-expansion-panel-content>
            Setor: {{ personalData.nomCcu }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </form>
</template>
<script>
import { findByNumCad } from "@/services/user-profile-senior";

export default {
  name: "Profile",
  data() {
    return {
      tab: null,
      items: ["Dados Pessoais", "Salarios", "Cargos", "Afastamento", "Ferias"],
      personalData: [],
      panel: [0, 1],
      disabled: false,
      readonly: false
    };
  },
  async created() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const data = await findByNumCad();
        this.personalData = data;
      } catch (e) {
        this.$handleHttpError(
          "Erro ao obter perfil de usuário. Entre em contato com o suporte."
        );
      }
    }
  }
};
</script>
<style scoped>
.v-expansion-panel-header,
.v-expansion-panel-content {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 15px;
}
</style>
